import React from 'react'

const News = () => {
  return (
    <section className="main_banner">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="drop-in"> ISOVIA News</h2>
        </div>
      </div>
    </div>
  </section>
  
  )
  
}

export default News